import VueTypes from 'vue-types';
import nullableVueType from '../../util/nullableVueType';


export default {
    name: 'SectionAbstract',

    props: {
        id: VueTypes.string,
        onReady: VueTypes.func,
        data: nullableVueType(VueTypes.object)
      },

      data() {
          return {
              transitioningIn: false,
              visible: false,
              treshhold: .1,
          }
      },

      beforeCreate() {
        
      },

      mounted() {
          this._innerHeight = window.innerHeight;
          this.$nextTick(() => {
            this._position = this.$el.getBoundingClientRect();
            // console.log(this._position, this._name);
            this.onReady(this);
          });

          this._transitionIntl = new TimelineLite({paused: true, onComplete: this.handleTransitionCompleted});
        //   if(this.$el.querySelector('.heading-section-js')) {
        //     const headerLines = new SplitText(this.$el.querySelector('.heading-section-js'), {type:"lines"});
        //     const $button = this.$el.querySelector('.button-js');
        //     const $headingSeparator = this.$el.querySelector('.heading-separator-js');
        //     const $description = this.$el.querySelector('.description-js');
        //     this._transitionIntl.staggerFrom(headerLines.lines, 2, {opacity:0, y: 80, ease: Expo.easeOut}, 0.1, 0);
        //     if($headingSeparator) {
        //         this._transitionIntl.from(this.$el.querySelector('.heading-separator-js'), 1, {scaleY:0, ease: Expo.easeOut}, "-=1.5" );
        //     }
        //     if($description) {
        //         this._transitionIntl.from($description, 2, {opacity:0, y: 80, ease: Expo.easeOut},  "-=1.5" );
        //     }
        //     if($button) {
        //         this._transitionIntl.from(this.$el.querySelector('.button-js'), 1, {opacity:0, y: 40, ease: Back.easeOut},  "-=1" );
        //     }
        //   }
      },

      methods: {

        addListeners() {
            window.addEventListener('resize', this.handleResize);
        },

        removeListeners() {
            window.removeEventListener('resize', this.handleResize);
        },

        enterViewport() {
            this._transitionIntl.play();
        },

        leaveViewport() {
        },

        beyondViewport() {
        },

        checkScroll(value) {
            // console.log(this._position.y, value.offset.y + window.innerHeight,this._position.y < value.offset.y + window.innerHeight);
        },

        handleResize() {
            this._innerHeight = window.innerHeight;
            this._position = this.$el.getBoundingClientRect();
        },

        transitionInThreshold() {
            // console.log('in view', this)
            // var targets = this._transitionIntl.getChildren();
            // for (var i=0; i< targets.length; i++) {
            // if (targets[i].target != null) {
            //     TweenLite.set(targets[i].target, {clearProps:"all"});
            // }
            // }
        }
      },

      watch: {
        scroll(value) {
            // this.checkScroll(value);
        }
      },

      beforeDestroy() {
          this.removeListeners();
      }
}