import wheel from 'wheel';
import { TweenLite } from '../node_modules/gsap/TweenMax';

class SmoothScroll {
	constructor(target, speed, smooth, callbackUpdate) {
		if (target == document) {
			target = (document.documentElement || document.body.parentNode || document.body) // cross browser support for document scrolling
		}
		this.target = target;
		this.moving = false
		this.smooth = smooth;
		this.speed = speed;
		this.callbackUpdate = callbackUpdate;
		this.pos = target.scrollTop
		this._scrolled = this.scrolled.bind(this);
		wheel.addWheelListener(this.target, this._scrolled);
		// this.target.addEventListener('mousewheel', this._scrolled, false)
		// this.target.addEventListener('DOMMouseScroll', this._scrolled, false)
		this._update = this.update.bind(this);
		this.stop = false;
	}

	scrolled(e) {
		// if(!this.stop) {
		// 	e.preventDefault();
		//  } else {
		// 	return;
		//  }
		let delta = e.deltaY;
		if (delta === undefined) {
			//we are on firefox
			delta = -e.detail;
		}
		delta = Math.max(-1, Math.min(1, delta)) // cap the delta to [-1,1] for cross browser consistency

		this.pos += delta * this.speed
		this.pos = Math.max(0, Math.min(this.pos, this.target.scrollHeight - this.target.clientHeight)) // limit scrolling
		this._currentDelta = Math.max(100, Math.abs(delta * this.speed));

        if (!this.moving) this.update();
	}

	enabled(value = true) {
		if(value) {
			this.stop = false;
			this.moving = false;
			this.target.style.overflowY = 'scroll';
		} else {
			this.stop = true;
			this.target.style.overflowY = 'hidden';
		}
	}

	update() {
		if(this.stop) return;
		this.moving = true
		
		const delta = (this.pos - this.target.scrollTop) / this.smooth;
		// this.target.scrollTop += delta
		if (this._currentDelta >= 0) {
			this._currentDelta -= this.speed;
			requestAnimationFrame(this._update);
            this.callbackUpdate && this.callbackUpdate(this.target.scrollTop, this._currentDelta );
        }
		else {
			this.moving = false
		}
	}

	scrollTo(value) {
		this.pos = value;
		TweenLite.to(this.target, 2, {scrollTo: {y:value}, ease: Expo.easeOut});
	}

	destroy() {
		this.stop = true;
		this.target.removeEventListener('mousewheel', this._scrolled, false)
		this.target.removeEventListener('DOMMouseScroll', this._scrolled, false)		
	}
}

export default SmoothScroll;


// export default function SmoothScroll(target, speed, smooth, callbackUpdate) {
	

// 	function scrolled(e) {
// 		e.preventDefault(); // disable default scrolling
// 		var delta = e.delta || e.wheelDelta;
// 		if (delta === undefined) {
// 			//we are on firefox
// 			delta = -e.detail;
// 		}
// 		delta = Math.max(-1, Math.min(1, delta)) // cap the delta to [-1,1] for cross browser consistency

// 		pos += -delta * speed
// 		pos = Math.max(0, Math.min(pos, target.scrollHeight - target.clientHeight)) // limit scrolling

//         if (!moving) update()
// 	}

// 	function update() {
// 		moving = true
// 		var delta = (pos - target.scrollTop) / smooth
// 		target.scrollTop += delta
// 		if (Math.abs(delta) > 0.5) {
//             requestFrame(update);
//             callbackUpdate && callbackUpdate(target.scrollTop);
//         }
// 		else {
// 			moving = false
//         }
// 	}

// 	function destroy() {
// 		window.scrollTo( 0, 0 );
// 		target.removeEventListener('mousewheel', scrolled, false)
// 		target.removeEventListener('DOMMouseScroll', scrolled, false)
// 	}

// 	const requestFrame = function() { // requestAnimationFrame cross browser
// 		return (
// 			window.requestAnimationFrame ||
// 			window.webkitRequestAnimationFrame ||
// 			window.mozRequestAnimationFrame ||
// 			window.oRequestAnimationFrame ||
// 			window.msRequestAnimationFrame ||
// 			function(func) {
// 				window.setTimeout(func, 1000 / 50);
// 			}
// 		);

// 		return this;
// 	}()
// }