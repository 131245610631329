import RouteNames from "./enum/RouteNames";
import { ControlActionsTypes } from "../store/module/control";

export default {
    [RouteNames.HOME]: {
        main: {rq: 'api/home.json'},
        landing: {rq: 'api/landing.json'},
    },
    [RouteNames.TOOLS]: {
        tool: {rq: 'api/tools/${slug}.json'},
    },
}