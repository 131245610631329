import SectionAbstract from "../SectionAbstract";
import VueTypes from 'vue-types';
import nullableVueType from "../../../util/nullableVueType";

export default {
  name: 'CopySection',

  extends: SectionAbstract,

  props: {
    data: VueTypes.objectOf({
      fontColor: nullableVueType(VueTypes.string).isRequired,
      backgroundColor: nullableVueType(VueTypes.string).isRequired,
      header: nullableVueType(VueTypes.string).isRequired,
      description: VueTypes.string.isRequired,
      fontSize: VueTypes.string.isRequired,
      subtype: VueTypes.string.isRequired,
    })
  },

  mounted() {
    // this._transitionIntl.from(this.$refs.copyDescription, .5, {opacity: 0, ease: Expo.easeOut}, 0);
    // this._transitionIntl.staggerFrom(this.$refs.copyDescription.querySelectorAll('strong'), 1, {opacity: 0, delay: .4}, 0.3, 0);
  }
};
