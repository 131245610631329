import { ContentActionTypes, ContentNamespace } from "../store/module/content";
import { mapState, mapMutations } from "vuex";

import TransitionPageState from "../data/enum/TransitionPageState";
import { TweenLite } from "gsap";
import { PageActionTypes } from "../store/module/page";
import SmoothScroll from "../util/SmoothScroll";
import LightBoxState from "../data/enum/LightBoxState";
import { ControlNamespace, ControlMutationTypes } from "../store/module/control";

 
export default {
	name: 'PageAbstract',

  data() {
    return {
      loaded: false,
      scroll: null,
      currentScrollDelta: 0,
      pageHeight: 0,
    }
  },

  mounted() {
		window.scrollTo( 0, 0 );
    this._smoothScroll = new SmoothScroll(document, 60,6, this.handleScroll);
    this._innerHeight = window.innerHeight;
    this.$nextTick(() => {
      this.pageHeight = this.$el.getBoundingClientRect().height
    });

    window.addEventListener('resize', this.handleResize);
  },

  computed: {
    ...mapState(ContentNamespace, ['pages']),

    ...mapState(ControlNamespace, ['lightBoxState', 'blockScroll', 'showMenu', 'activeNav']),

    mainData() {
      return this.main[0];
    }
  },

  methods: {
    ...mapMutations({
      setActiveNav: ControlMutationTypes.ACTIVE_NAV,
    }),

    handleScroll(scrollTop, currentDelta) {
      this.scroll = scrollTop;
      this.currentScrollDelta = currentDelta;
      if(this.scroll >= 20) {
        if(!this.activeNav) {
          this.setActiveNav(true);
        }
      } else {
        this.setActiveNav(false);
      }
    },

    handleResize(event) {
      this.pageHeight = this.$el.getBoundingClientRect().height
    }
  },

	transition: {
        css: false,
        beforeLeave (el, second) {
          console.log('leaving', el, second)
          // TweenLite.to(el, 1, {opacity: 0});
        },
        leave (el, done) {
          // Scroll Up
          done();
          // TweenLite.delayedCall(2, done );
        },
       
        // enter (el, done) {
        //   this.$nextTick(() => {
        //     const page = this.$store.getters[`${ContentNamespace}/pageByName`](this.$route.name);
        //     console.log('enter', el, page)
        //     if(page) {
        //       this.$store.commit(PageActionTypes.TRANSITION_IN_PAGE, TransitionPageState.ENTER);
        //     }
        //   })
        //   done()
        // }
  },

  watch: {
    lightBoxState(value) {
      if(value !== LightBoxState.HIDDEN) {
        this._smoothScroll.enabled(false);
      } else {
        this._smoothScroll.enabled();
      }
    },
    
    blockScroll(value) {
      this._smoothScroll.enabled(!value);
    },

    showMenu(value) {
      if(this.activeNav) return;
    }
  },

  beforeDestroy() {
    this._smoothScroll.destroy();
    window.removeEventListener('resize', this.handleResize);
  },

  beforeRouteLeave (to, from, next) {
    this.$store.commit(PageActionTypes.TRANSITION_IN_PAGE, TransitionPageState.BEFORE_LEAVE);
    // next();
    TweenLite.delayedCall(.1, next);
  }
}
