import { ContentActionTypes, ContentNamespace } from "../store/module/content";
import TransitionPageState from "../data/enum/TransitionPageState";
import PageRequests from "../data/PageRequests";
import PageAbstract from "./PageAbstract";
import { PageActionTypes } from "../store/module/page";
import { ControlActionsTypes } from "../store/module/control";
 
export default {
	name: 'PageAsyncDataAbstract',

  extends: PageAbstract,

  async asyncData ({ store, params, route, app }) {
    
    // console.log('request data', route.name, params)
    const routeName = route.name.split('-')[0];
    const requests = [];
    
    if(typeof PageRequests[route.name] === 'undefined') {
      return {}
    }
    
    if (Object.keys(PageRequests[route.name]).includes('main')) {
       requests.push({id: 'main', rq: `api/${routeName}.json`, valid: true});
    }

    if(PageRequests[route.name]) {
      Object.keys(PageRequests[route.name]).forEach(key => {
        let {rq} = PageRequests[route.name][key];
        rq = rq.replace('${slug}',  params.slug);
        if(rq.indexOf('${secondSlug}') !== -1) {
          rq = rq.replace('${secondSlug}', params.secondSlug ? params.secondSlug : '${not-valid}');
        }
        requests.push({...PageRequests[route.name][key], id: key, rq, valid: rq.indexOf('${not-valid}') === -1});
      });
    }

    const validRequests = requests.filter(request => request.valid);
    const invalidRequests = requests.filter(request => !request.valid);
    const response = await Promise.all(validRequests.map(request => app.$axios.$get(request.rq)));
    // store.dispatch(ContentActionTypes.GET_PAGE, {pageId: route.name, data: response[0].data[0]});
    store.dispatch(ContentActionTypes.GET_PAGE, {pageId: route.name, data: response[0]});
    store.commit(PageActionTypes.TRANSITION_IN_PAGE, TransitionPageState.ENTER);

    const data = response.reduce((result, currentObject, index) => {
      result[requests[index].id] = currentObject.data;
      result[`${requests[index].id}_meta`] = currentObject.meta;
      if(requests[index].ac) {
        store.commit(requests[index].ac, {data: result[requests[index].id][0]});
      }
      return result;
    }, {})

    // Commmit events dynamically from PageRequests
    invalidRequests.forEach(request => {
      data[request.id] = []
    });
    
    return data;
  },

}
